import { NextPage } from "next";
import NextErrorPage from "next/error";

import Layout from "@/components/UI/Layout";

const Error404Page: NextPage = () => {
  return (
    <Layout>
      <NextErrorPage statusCode={404} />
    </Layout>
  );
};

export default Error404Page;
